.login {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: url(../images/fondo_principal.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;

  &::-webkit-scrollbar {
    display: none;
  }

  .login__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: max-content;
    min-height: 100vh;

    padding: 2rem 0;
    background-color: rgba(23, 43, 68, 0.7);
  }

  .login__card {
    width: 100%;
    max-width: 500px;
    height: min-content;
    padding: 25px 3rem 0;
    border-radius: 12px;
    box-shadow: 0 3px 12px rgb(0, 0, 0);

    @include themify($themes) {
      background-color: themed("bkgCard");
    }

    .login__head {
      margin-bottom: 30px;
      padding-left: 10px;

      .login__head_logo {
        width: 90%;
        height: 180px;
        margin: 12px 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;

        @include themify($themes) {
          background-image: themed("logoCard");
        }
      }

      .login__head_title {
        margin: 18px 0 32px;
        font-size: 0.8rem;
        line-height: 16px;
        white-space: pre-line;
        @include themify($themes) {
          color: themed("colorTitle");
        }
      }
    }

    p {
      margin: 15% 0 18px;
      font-size: 0.9rem;
      text-align: center;

      @include themify($themes) {
        color: themed("textPrimary");
      }

      i {
        font-size: 0.7rem;
        opacity: 0.5;

        span {
          font-weight: bold;
        }
      }
    }
  }
}

.login__forgot-password {
  position: absolute;
  font-size: 0.7rem;
  line-height: 15px;
  bottom: -22px;
  right: 12px;

  a {
    @include themify($themes) {
      color: themed("colorLinck");
    }

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
    bottom: -24px;
  }
}

@media screen and (max-width: 520px) {
  .login {
    background-position: top left;

    .login__wrapper {
      height: auto;
    }

    .login__card {
      padding: 25px 0.6rem 0;
      margin: 0.6rem;
    }
  }
}
