.alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;

  @include themify($themes) {
    background-color: themed("bkgShadow");
  }

  .alert__content {
    position: relative;
    width: min-content;
    min-width: 250px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
    border-radius: 12px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.9);
    overflow: auto;
    @include themify($themes) {
      background-color: themed("bkgBody");
    }

    .alert__icon {
      padding: 18px;
      display: flex;

      svg {
        width: 36px;
        height: 36px;
        margin: auto 0;
        @include themify($themes) {
          color: themed("textPrimary");
        }

        &.green {
          color: green;
          stroke-width: 0.6px;
          stroke: green;
        }

        &.red {
          color: red;
          stroke-width: 0.6px;
          stroke: rgb(255, 255, 255);
          //   filter: drop-shadow(0 0 4px rgb(255, 255, 255));
        }

        &.yellow {
          color: yellow;
          stroke-width: 0.6px;
          stroke: rgb(0, 0, 0);
        }
      }
    }

    p {
      position: relative;
      margin: 0 8px;
      font-size: 0.95rem;
      text-align: center;
      white-space: pre-wrap;
      @include themify($themes) {
        color: themed("textPrimary");
      }
    }

    .close {
      font-size: 1rem;
      font-weight: bold;
      margin: 18px 0 0;
      padding: 6px 12px;
      text-decoration: none;
      cursor: pointer;
      @include themify($themes) {
        color: themed("textPrimary");
      }

      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }

    .in_row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;

      .close {
        display: block;
        width: 100%;
        margin: 18px 4px 0;
        text-align: center;
      }
    }

    &._red {
      background-color: rgb(255, 0, 0);

      .alert__icon {
        svg {
          &.green,
          &.yellow,
          &.red {
            stroke-width: 0;
            color: rgb(255, 255, 255);
          }
        }
      }

      p {
        color: rgb(255, 255, 255);
      }

      .close {
        color: rgb(255, 255, 255);
      }
    }

    &._yellow {
      background-color: rgb(255, 255, 0);

      .alert__icon {
        svg {
          &.green,
          &.yellow,
          &.red {
            stroke-width: 0;
            color: rgb(30, 30, 30);
          }
        }
      }

      p {
        color: rgb(30, 30, 30);
      }

      .close {
        color: rgb(30, 30, 30);
      }
    }
  }
}

.alert__input {
  display: block;
  position: absolute;
  left: 0;
  top: 36px;
  width: min-content;
  padding: 3px 12px 2px;
  margin-top: 4px;
  margin-right: 18px;
  font-size: 0.8rem;
  border-radius: 8px;
  white-space: nowrap;
  color: rgb(240, 240, 240);
  z-index: 6;
  @include themify($themes) {
    //color: themed("textPrimary");
    background-color: themed("bkgColorAlert");
  }

  &._up {
    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: -10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      @include themify($themes) {
        border-bottom: 8px solid themed("bkgColorAlert");
      }
    }
  }

  &._down {
    top: -2px;
    z-index: 90;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin-top: 16px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      @include themify($themes) {
        border-top: 8px solid themed("bkgColorAlert");
      }
    }
  }
}

.error {
  position: relative;
  margin: 1rem 0 0;
  font-size: 1rem;
  font-weight: bold;
  @include themify($themes) {
    color: themed("colorError");
  }

  svg {
    position: absolute;
    top: -1.2rem;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
  }
}
