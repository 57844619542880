.form,
#root,
.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  input,
  select,
  textarea {
    width: 100%;
    padding: 8px 10px;
    font-size: 0.9rem;
    transition: all 0.3s;
    @include themify($themes) {
      color: themed("textPrimary") !important;
      background-color: themed("bkgInbox");
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      @include themify($themes) {
        -webkit-text-fill-color: themed("textPrimary") !important;
        text-fill-color: themed("textPrimary") !important;
        -webkit-box-shadow: 0 0 0px 50px themed("bkgInbox") inset;
        box-shadow: 0 0 0px 50px themed("bkgInbox") inset;
        caret-color: themed("textPrimary") !important;
        border: none;
        border-radius: 0 8px 8px 0 !important;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &[disabled] {
      @include themify($themes) {
        background-color: themed("bkgBodyContra");
      }

      &:focus,
      &:active {
        @include themify($themes) {
          border-color: themed("colorBorder");
        }
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  textarea {
    resize: none;
    height: 60px;
  }

  fieldset {
    position: relative;
    width: 70%;
    margin: 2rem auto;
    border-radius: 12px;
    @include themify($themes) {
      border: 1px solid themed("colorBorderFieldset");
    }
  }

  legend {
    padding: 0 8px;
    @include themify($themes) {
      color: themed("textPrimary");
    }
  }

  svg {
    &.close {
      position: absolute;
      top: -0.5rem;
      right: 0.2rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      z-index: 10;
    }

    &.edit {
      position: absolute;
      top: 0;
      right: 0.2rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 520px) {
    input,
    textarea {
      padding: 10px 12px;
      font-size: 1.1rem;
    }

    fieldset {
      width: 100%;
    }

    svg {
      &.close {
        width: 1.9rem;
        height: 1.9rem;
      }
    }

    .form_group-label {
      font-size: 1.1rem !important;
    }
  }
}

.form_group {
  position: relative;
  width: 100%;
  margin: 0 0 20px;

  &.unable {
    opacity: 0.5;
    cursor: not-allowed;

    input {
      pointer-events: none;
    }
  }

  .cont_tags {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    margin: 0.6rem 0 0;

    span.tag {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: max-content;
      margin: 0.1rem 0.2rem;
      padding: 0.2rem 0 0.2rem 0.5rem;
      border-radius: 0.8rem;
      font-size: 0.9rem;
      line-height: 1.3;
      @include themify($themes) {
        color: themed("textPrimary");
        background-color: themed("bkgInbox");
        border: 1px solid rgba(themed("colorBorderFieldset"), 0.1);
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 0.2rem 0 0.4rem;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}

.form_group-field {
  width: 100%;
  display: flex;
  margin: auto;

  @include themify($themes) {
    color: themed("textPrimary") !important;
    background-color: themed("bkgInbox");
    border: 1px solid themed("colorBorderFieldset");
  }

  &.input_radius {
    border-radius: 8px;
    // overflow: hidden;

    .input {
      width: 100%;
    }
  }

  &.read_only {
    pointer-events: none;
  }

  .radius_right {
    border-radius: 0 8px 8px 0;
  }

  .radius_all {
    border-radius: 8px;

    &.unable {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input,
  select,
  textarea {
    border: none;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    svg {
      position: relative;
      width: 2rem;
      height: 2rem;
      cursor: auto;
    }
  }
}

.title_with_icon {
  position: relative;
  display: inline-block;
  width: max-content;
  height: min-content;
  margin: 2rem 0 0;
  padding: 0.4rem 2.6rem 0.4rem 1rem;
  font-size: 1.2rem;

  svg {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  &._left {
    padding: 0.4rem 1rem 0.4rem 2.6rem;

    svg {
      right: auto;
      left: 0.5rem;
    }
  }

  @media screen and (max-width: 520px) {
    margin: 0 0 1rem;

    svg {
      top: 0.6rem;
    }
  }
}

.form_group-title {
  width: 100%;
  display: block;
  margin: 0 0 0.6rem;

  @include themify($themes) {
    color: themed("textPrimary");
  }
}

.form_group-label {
  margin-bottom: 4px;
  display: inline-block;

  @include themify($themes) {
    color: themed("textPrimary");
  }
}

.form_group-check {
  display: flex;
  flex-flow: row nowrap;
  height: min-content;
  white-space: nowrap;
  margin: 0.5rem 1rem 1rem 0;
  padding: 0.4rem 0.9rem 0.4rem 0.2rem;
  border-radius: 8px;
  cursor: pointer;
  @include themify($themes) {
    color: themed("textPrimary");
    border: 1px solid themed("colorBorderFieldset");
  }

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    margin: 4px 8px 0;
    pointer-events: none;
  }

  span {
    display: inline-block;
    font-size: 1rem;
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    @include themify($themes) {
      background-color: themed("bkgBtnsHover");
    }
  }

  &._bottom {
    margin-top: auto;
    margin-left: 1.4rem;
  }

  @media screen and (max-width: 520px) {
    padding: 0.5rem 1rem 0.5rem 0.3rem;

    input[type="checkbox"] {
      width: 1.1rem;
      height: 1.1rem;
    }

    span {
      font-size: 1.1rem;
    }

    &._bottom {
      margin-top: auto;
      margin-left: 0.8rem;
    }
  }
}

.form_group-button {
  position: absolute;
  right: 0;
  padding: 6px 6px 0;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0 7px 7px 0;
  border: none;
  background-color: transparent;
  z-index: 5;

  @include themify($themes) {
    color: themed("textPrimary");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  @media screen and (max-width: 520px) {
    padding: 8px 6px 0;

    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.form_group-icon {
  padding: 6px 6px 0;
  border-radius: 8px 0 0 8px;

  @include themify($themes) {
    background-color: themed("colorBorderTenue");
    border: 1px solid themed("colorBorderTenue");
    border-right: 1px solid themed("colorBorderFieldset");
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  @media screen and (max-width: 520px) {
    padding: 8px 6px 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.input {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  svg {
    position: absolute;
    right: 0;
    width: 2rem;
    height: 2rem;
    padding: 6px !important;
    margin: 0 !important;
    cursor: pointer;
    transition: all 200ms;

    @include themify($themes) {
      color: themed("textPrimary");
    }

    &.no_event {
      pointer-events: none !important;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &.with_icon_right {
    input {
      //   padding-right: 28px;
      padding-right: 1.6rem !important;
    }
  }

  @media screen and (max-width: 520px) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

._float_list {
  position: absolute;
  top: 30px;
  width: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  overflow: auto;
  pointer-events: all;
  scrollbar-color: rgb(160, 160, 160) rgba(0, 0, 0, 0);
  scrollbar-width: 10px;
  z-index: 100;
  @include themify($themes) {
    background-color: themed("bkgInbox");
    border: 1px solid themed("colorBorderFieldset");
  }

  &._on_top {
    top: auto;
    bottom: 30px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    @include themify($themes) {
      background-color: themed("bkgBody");
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    @include themify($themes) {
      border: 1px solid themed("colorScrollbar");
      background-color: themed("colorScrollbar");
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background-color: themed("textPrimary");
    }
  }

  li {
    width: 100%;
    min-height: 24px;
    display: block;
    padding: 6px 6px;
    font-size: 0.9rem;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
    background-color: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    @include themify($themes) {
      color: themed("textList");
    }

    &._selected {
      @include themify($themes) {
        background-color: themed("colorSelectList");
      }

      &:hover {
        @include themify($themes) {
          background-color: darken(themed("colorHoverList"), 10%);
        }
      }
    }

    &:hover {
      @include themify($themes) {
        background-color: themed("colorHoverList");
      }
    }
  }

  @media screen and (max-width: 520px) {
    li {
      padding: 8px 6px;
      font-size: 1rem;
    }
  }
}

.content_sticky_bottom {
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: 20rem;
  margin: 1rem auto 0;
  padding: 0.6rem 1.2rem 0;
  border-radius: 12px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include themify($themes) {
    color: themed("textPrimary") !important;
    background-color: themed("bkgBody");
    border: 1px solid themed("colorBorderFieldset");
  }

  h2 {
    display: block;
    margin: 0;
    font-size: 1rem;
    line-height: 1.3;
    text-align: center;
    font-weight: normal;

    span {
      display: inline-block;
      text-align: left;

      &:first-child {
        min-width: 8rem;
        text-align: right;
        margin-right: 0.9rem;
        margin-left: -3rem;
      }

      &:last-child {
        min-width: 6rem;
        text-align: left;
      }
    }

    &:nth-last-child(2) {
      padding-top: 0.3rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .form_group {
    max-width: 8rem;
    margin: 0 auto;

    .form_group-field {
      border: none;
      input {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 520px) {
    width: calc(100% + 24px);
    max-width: none;
    margin-left: -12px;
    padding: 0.8rem 1.4rem 0;

    .form_group {
      max-width: 9rem;
    }
  }
}

.content_primary {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .form_group {
    width: 45%;
    margin: 0 22px 20px;
  }

  .detalles {
    margin-top: -18px;
    padding: 1.6rem 2rem 1rem;
    border-radius: 12px;
    @include themify($themes) {
      border-bottom: 1px solid themed("colorBorderFieldset");
      border-left: 1px solid themed("colorBorderFieldset");
      border-right: 1px solid themed("colorBorderFieldset");
    }

    span {
      display: inline-block;
      margin: 0 0 0.3rem;
      padding: 0.3rem 0.6rem;
      @include themify($themes) {
        border-bottom: 1px solid themed("colorBorderFieldset");
      }
    }

    b {
      padding-right: 0.4rem;
      font-size: 1.1rem;
    }

    @media screen and (max-width: 520px) {
      padding: 1.6rem 0.8rem 1rem;

      span {
        padding: 0.3rem;
      }

      b {
        font-size: 1.2rem;
      }
    }
  }

  &._on_details {
    &._center {
      justify-content: center;
    }

    .form_group-field {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      background-color: transparent;

      input {
        background-color: transparent !important;
        font-size: 1.4rem;
      }
    }

    .form_group-icon {
      background-color: transparent;
      border-color: transparent;

      svg {
        margin-top: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .detalles {
      display: inline-block;
      max-width: 370px;
      margin: 1rem 0.6rem 0;
      padding: 1.6rem 2rem 1rem;
      border-radius: 12px;
      @include themify($themes) {
        border: 1px solid themed("colorBorderFieldset");
      }

      strong {
        font-size: 1.2rem;
      }

      span {
        display: inline-block;
        margin: 0 0 0.3rem;
        padding: 0.3rem 0.6rem;
        @include themify($themes) {
          border-bottom: 1px solid themed("colorBorderFieldset");
        }
      }

      b {
        padding-right: 0.4rem;
        font-size: 1.1rem;
      }

      @media screen and (max-width: 520px) {
        padding: 1.6rem 0.8rem 1rem;

        span {
          padding: 0.3rem;
        }

        b {
          font-size: 1.2rem;
        }
      }
    }
  }

  &._on_popup {
    width: 100%;

    .form_group {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &._with_dropzone {
        .dropzone {
          height: 60px;

          .dropzone__input {
            width: 100%;
            min-height: auto;

            .dropzone__drop-here p {
              width: 60%;
              text-align: center;
            }
          }
        }
      }
    }

    .field_text {
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 0 8px 8px;
      padding: 0;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;

      span {
        display: inline;
        width: 100%;
        padding: 0 0.8rem;
        margin-left: 0.4rem;
        font-size: 1.1rem;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        font-size: 0.8rem;
        float: right;
        font-weight: lighter;
        font-style: normal;
        font-family: GTPressuraLight;
      }
    }

    textarea {
      border-radius: 8px;
    }
  }

  &._pedido_voucher {
    max-width: 300px;
    margin: 0 auto;

    .form_group {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &._pedido_detalle {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    padding: 2.4rem 0.7rem 0.4rem;
    position: relative;

    .info_btns {
      width: 96%;
      position: absolute;
      top: 0;
      display: flex;
      flex-flow: row nowrap;
      padding: 0 0.7rem;
      align-content: center;

      .btn_pequenio {
        position: relative;
        top: 0.4rem;
      }
    }

    .in_column {
      position: relative;
      display: block;

      width: 100%;
      margin: 0;

      .info_card {
        position: relative;
        cursor: auto;
        padding: 0.6rem 0.8rem;
        margin: 0.3rem 0;
        border-radius: 8px;
        @include themify($themes) {
          background-color: themed("colorCorporate");
          border: 1px solid themed("colorBorderFieldset");
        }

        svg.icon_loading {
          pointer-events: none;
          margin-bottom: -0.4rem;
        }

        h5 {
          margin: 0;
          padding: 0;
          font-size: 1.1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          sup {
            margin: 0;
            padding: 0.1rem 0.4rem 0.2rem 0.4rem;
            font-size: 0.6rem;
            line-height: 1.3;
            font-family: GTPressuraLight;
            border-radius: 8px;
            color: rgb(250, 250, 250);
            background-color: rgba(0, 0, 0, 0.7);
          }
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;

          &.info_name {
            margin: 0.1rem 0 0.3rem;
            padding: 0;
            font-size: 0.9rem;
            line-height: 1;
          }

          &.info_nota {
            margin: 0.2rem 0 0.9rem;
            font-size: 0.9rem;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.info_attributes {
            margin: 0.2rem 0 0;
            font-size: 0.9rem;
            line-height: 1.3;

            b {
              display: block;
              margin: 0.5rem 0 0;
              padding: 0;
            }
          }

          &.info_address {
            margin: 0.2rem 0 0.3rem;
            font-size: 0.8rem;
            line-height: 1;
          }

          &.info_orders {
            padding: 0.2rem 0 0.9rem;
            font-size: 1rem;
          }

          &.info_document {
            margin: 0;
            max-width: 25rem;
            font-size: 0.9rem;
            overflow: hidden;
            white-space: pre-line;
            text-overflow: ellipsis;
          }

          &.info_product {
            margin-top: 0.2rem;
            font-size: 0.9rem;
            overflow: hidden;

            span {
              font-family: GTPressuraLight;
            }
          }

          &.cont_tags {
            display: flex;
            flex-flow: row wrap;
            justify-content: left;
            margin: 0.6rem 0 0;
            padding: 0;

            span.tag {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              width: max-content;
              margin: 0.1rem 0.2rem;
              padding: 0.2rem 0.5rem;
              border-radius: 0.8rem;
              font-size: 0.9rem;
              line-height: 1.3;
              @include themify($themes) {
                color: themed("textPrimary");
                background-color: themed("bkgInbox");
                border: 1px solid rgba(themed("colorBorderFieldset"), 0.1);
              }

              svg {
                width: 1.2rem;
                height: 1.2rem;
                margin: 0 0.2rem 0 0.4rem;
                padding: 0;
                cursor: pointer;
              }
            }
          }

          &.info_price {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0.1rem 0.6rem;
            font-size: 0.9rem;
            font-weight: normal;
            line-height: 1;

            .center {
              margin-left: 2rem;
              font-size: 0.8rem;
              font-family: GTPressuraLight;
            }

            .right {
              float: right;
            }

            &._total {
              margin: 0.6rem 0 0;
              font-weight: bold;
            }
          }
        }

        i {
          overflow: hidden;
          text-overflow: ellipsis;

          &.info_email {
            display: block;
            font-size: 0.8rem;
            font-weight: lighter;
            line-height: 1;
            margin: 0 0 0.4rem;
            @include themify($themes) {
              color: themed("colorLinck");
            }
          }

          &.info_phone {
            display: block;
            font-size: 0.9rem;
            font-weight: lighter;
          }

          b {
            font-weight: bold;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgb(35, 35, 41);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 1px solid rgb(100, 100, 100);
      background-color: rgb(100, 100, 100);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(230, 230, 230);
    }

    @media screen and (max-width: 520px) {
      flex-flow: column nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &._in_center {
    justify-content: center;
    width: 100%;

    .in_column {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: 350px;
      margin: 0 1rem;

      .form_group {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        textarea {
          border-radius: 8px;
        }
      }

      h5 {
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
        font-weight: normal;
        font-family: GTPressuraRegular;

        span {
          font-family: GTPressuraLight;
        }
      }

      h4 {
        position: relative;
        margin: 0;
        padding: 0 0 0.3rem;
        font-size: 1.1rem;
        font-weight: bold;

        span {
          font-size: 0.9rem;
        }

        &.total_items {
          padding: 0 1rem;
          font-weight: normal;

          b {
            font-size: 0.9rem;
            font-weight: bolder;
            @include themify($themes) {
              color: themed("colorLinck");
            }
          }
        }
      }

      .swap_icons {
        position: absolute;
        top: 1.4rem;
        right: 0.3rem;
        cursor: pointer;
        z-index: 10;

        svg {
          display: inline-block;
          pointer-events: none;

          &.arrow {
            width: 1.1rem;
            height: 1.1rem;
            margin: 0 -0.3rem -0.5rem 0;
          }

          &.primay {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
          }
        }
      }
    }

    fieldset.voucher {
      width: 100% !important;
      max-width: 350px;
      margin: 0 1rem !important;

      .form_group {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 948px) {
    .form_group {
      margin: 0 6px 20px;
    }
  }

  @media screen and (max-width: 520px) {
    .form_group {
      margin: 0 12px 20px;
      width: 100%;
    }
  }
}

.content_column {
  display: block;
  flex-direction: column;
  margin: 2rem 1.4rem;
  text-align: center;

  p {
    margin: 1rem 0 2rem;
  }

  @media screen and (max-width: 520px) {
    margin: 2rem 0.8rem;
  }
}

.content_btns {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  &._with_margin {
    margin: 0.8rem 0 1rem;
  }

  &._without_margin {
    margin: 0;

    .btn {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &._multiple {
    justify-content: space-evenly;
  }

  &._center {
    .btn.btn_lazy_load {
      margin: 0 1rem;
    }
  }

  &._floating {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &.in_movil {
    span:has(> .btn_add_to_list._right) {
      padding: 0;
      margin: 0 0 0 auto;
    }

    @media screen and (max-width: 520px) {
      .btn_add_to_list {
        margin: 1rem auto;
      }
    }
  }
}

.content_tabs {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 2rem;
  margin-bottom: -1px;
  padding: 0 1rem;

  ._tab {
    opacity: 0.5;
    cursor: pointer;
    position: relative;
    border-radius: 8px 8px 0 0;
    @include themify($themes) {
      border-top: 1px solid themed("colorBorderFieldset");
      border-left: 1px solid themed("colorBorderFieldset");
      border-right: 1px solid themed("colorBorderFieldset");
      background-color: themed("bkgBody");
    }

    &._active {
      opacity: 1;
      z-index: 3;
    }

    h4 {
      margin: 0;
      padding: 0.2rem 0.7rem 0.3rem;
    }
  }

  .btn.btn_with_icon._left {
    position: absolute;
    margin: -12px 0 0;
    right: 16px;
  }
}

.content_collapse {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 1rem 32px 20px;
  transition: all 0.3s;
  height: 4.2rem;
  overflow: hidden;

  &._open {
    height: auto;

    .btn {
      border: none;
      background-color: transparent;

      &:hover {
        background-color: transparent;

        &:before {
          height: 0;
        }
      }
    }

    &._detalle {
      .btn {
        cursor: default;
      }

      .form_group-check {
        border: none;
        border-radius: 0;
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  div {
    display: block;
    width: calc(100% + 64px);
    margin-left: -8px;

    .btn {
      margin: 8px 0;
      padding: 8px 2.3rem 8px 3rem;
    }

    &.breack_line {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: left;

      i {
        font-size: 0.8rem;
      }

      hr {
        width: 3rem;
        margin: 0;
        opacity: 1;

        &:first-child {
          margin-right: 0.7rem;
        }

        &:last-child {
          margin-left: 0.7rem;
        }
      }
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 8px;
    width: 2rem;
    height: 2rem;
    margin: 0;

    &.chevron {
      top: 0.35rem;
      right: 6px;
      left: auto;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media screen and (max-width: 520px) {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    padding: 1rem 0 20px;

    div {
      width: 100%;
      margin-left: 12px;
    }

    svg {
      top: 2px;

      &.chevron {
        top: 0.5rem;
      }
    }
  }
}

.content_messages {
  position: fixed;
  bottom: 0;
  right: 8px;
  width: 100%;
  max-width: 250px;
  pointer-events: none;
  z-index: 20;

  .messages_card {
    margin: 12px 0;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    background-color: green;
    color: aliceblue;
    // opacity: 0;
    // animation: desvanece 30s ease-in-out;
  }

  .messages_title {
    margin: 0 0 0.3rem;
    padding: 0;
    font-size: 0.9rem;
    line-height: 1.1;
  }

  .messages_text {
    margin: 0;
    padding: 0;
    font-family: GTPressuraLight;
    font-size: 0.8rem;
    line-height: 1.1;
    white-space: pre-line;
  }

  @keyframes desvanece {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

.internal_frame {
  padding: 1rem 0;
  border-radius: 1rem;
  @include themify($themes) {
    border: 1px solid themed("colorBorderFieldset");
  }

  .form_group {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .btn {
    margin: 2rem 1.4rem 0;
  }

  .p_in_row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    font-size: 1rem;
    @include themify($themes) {
      border-top: 1px solid themed("colorBorderFieldset");
    }

    &:last-child {
      @include themify($themes) {
        border-bottom: 1px solid themed("colorBorderFieldset");
      }
    }

    span {
      width: 100%;
      padding: 0.3rem 1rem;
      text-align: center;
      @include themify($themes) {
        border-right: 1px solid themed("colorBorderFieldset");
      }

      &:last-child {
        border-right: none;
      }
    }

    b {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 948px) {
    .form_group {
      margin: 0 16px 20px;
    }
  }

  @media screen and (max-width: 520px) {
    .btn {
      margin: 2rem 0.8rem 0;
    }
  }
}

.container {
  max-width: 1024px;

  &._detalle {
    .head_bar,
    .content_primary,
    .content_collapse {
      pointer-events: none;
    }

    ._on_popup {
      pointer-events: all;
    }
  }

  .head_bar.guide {
    margin-bottom: 2rem;
  }
}

.content_to_print {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include themify($themes) {
    background-color: themed("bkgBodyContra");
  }

  .marco_iframe {
    width: 100%;
    height: 100%;
    margin: 0 0 0.3rem;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      margin-top: -2px;
      margin-left: -4px;
      margin-bottom: -8px;

      scrollbar-width: none; /* Para Firefox */
      -ms-overflow-style: none; /* Para Internet Explorer y Edge */

      &::-webkit-scrollbar {
        display: none; /* Para Chrome, Safari y Opera */
      }
    }
  }

  a {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    text-decoration: none;
  }

  &._bill_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;

    ._bill {
      position: relative;
      overflow: hidden;

      .in_column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .in_row {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .info_greeting {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        text-align: center;
      }

      .info_logo_hit {
        display: block;
        width: 100%;
        height: 60px;
        margin: 0;
        padding: 0;
        background-repeat: no-repeat;
        background-size: 50px;
        background-position: center;
        @include themify($themes) {
          background-image: themed("logoCard");
        }
      }

      .info_tags {
        display: block;
        width: 100%;
        margin: 0;
        margin-left: 0.6rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
        font-weight: bold;
        border-radius: 6px;
        text-align: center;
        @include themify($themes) {
          border: 1px solid themed("textPrimary");
        }
      }

      .info_price {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.5rem 0.9rem 0.3rem;
        font-size: 1.1rem;
        font-weight: bold;
      }

      .info_date {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.2rem 0.9rem 0;
        font-size: 0.8rem;
        font-weight: bold;
      }

      .info_number {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.4rem 0.9rem 0;
        font-size: 1.9rem;
        font-weight: bold;
      }

      .info_notas {
        display: block;
        width: 95%;
        margin: 0 auto 0.6rem;
        margin-left: 0.6rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.9rem;
        font-weight: normal;
        border-radius: 6px;
        text-align: center;
        @include themify($themes) {
          border: 1px solid themed("textPrimary");
        }

        b {
          font-size: 0.8rem;
        }
      }

      .info_customer {
        display: block;
        width: 95%;
        margin: 0 auto;
        padding: 0.3rem 0.9rem;
        @include themify($themes) {
          border: 2px dashed themed("textPrimary");
        }

        span {
          display: block;
          font-size: 0.8rem;
          font-family: GTPressuraLight;

          b {
            font-size: 0.9rem;
            font-family: GTPressuraRegular;
          }
        }
      }

      .info_articles {
        margin: 1rem 0 0.8rem;
        width: 100%;

        ._center {
          text-align: center;
        }

        ._right {
          text-align: right;
        }

        table {
          width: 95%;
          margin: 0 auto;
          border-collapse: collapse;

          thead {
            th {
              padding: 0.2rem 0.5rem;
              font-size: 0.9rem;
              background-color: rgb(47, 51, 55);
              color: rgb(240, 248, 255);
              border: 1px solid rgb(47, 51, 55);
            }
          }

          tbody {
            tr {
              border: 1px solid rgb(47, 51, 55);

              td {
                padding: 0.2rem 0.5rem;
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      .info_footer {
        position: sticky;
        bottom: 8px;
        left: 0;
        display: block;
        width: 100%;
        margin-top: 2rem;
        @include themify($themes) {
          background-color: themed("bkgBodyContra");
        }

        .info_price {
          display: block;
          width: 50%;
          margin: 0 0 0 47.5%;
          padding: 0.2rem 0.6rem;
          font-size: 1rem;
          font-weight: bold;

          .right {
            float: right;
          }

          &._total {
            margin-bottom: 0.8rem;
            background-color: rgb(47, 51, 55);
            color: rgb(240, 248, 255);
          }
        }

        h6 {
          display: block;
          width: 88%;
          margin: 0 auto;
          padding: 0.3rem 0.9rem;
          font-size: 0.6rem;
          font-weight: normal;
          text-align: center;
          @include themify($themes) {
            border: 2px dashed themed("textPrimary");
          }
        }
      }
    }
  }

  &._bill_column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;

    ._bill {
      position: relative;
      overflow: hidden;

      .in_column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .in_row {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .info_greeting {
        display: block;
        width: 100%;
        margin: 0.3rem 0 0;
        padding: 0;
        font-family: GTPressuraLight;
        font-size: 0.8rem;
        text-align: center;

        span {
          font-family: GTPressuraRegular;
          font-size: 0.9rem;
        }
      }

      .info_logo_hit {
        display: block;
        width: 100%;
        height: 90px;
        margin: 0.3rem 0 0;
        padding: 0;
        background-repeat: no-repeat;
        background-size: 90px;
        background-position: center;
        @include themify($themes) {
          background-image: themed("logoCard");
        }
      }

      .info_tags {
        display: block;
        width: 98%;
        margin: 0;
        padding: 0.3rem 0.8rem;
        font-family: GTPressuraLight;
        font-size: 0.8rem;
        font-weight: bold;
        border-radius: 6px;
        text-align: center;
        @include themify($themes) {
          border: 1px solid themed("textPrimary");
        }
      }

      .info_price {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.5rem 0.9rem 0.3rem;
        font-size: 1.1rem;
        font-weight: bold;
      }

      .info_number {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.4rem 0.9rem 0;
        font-size: 1.9rem;
        font-weight: bold;
      }

      .info_notas {
        display: block;
        width: 94%;
        margin: 0 auto 0.6rem;
        padding: 0.3rem 0.8rem;
        font-size: 0.8rem;
        font-family: GTPressuraLight;
        font-weight: normal;
        border-radius: 6px;
        text-align: center;
        @include themify($themes) {
          border: 1px solid themed("textPrimary");
        }

        b {
          font-size: 0.8rem;
          font-family: GTPressuraRegular;
        }
      }

      .info_customer {
        display: block;
        width: 94%;
        margin: 0.4rem auto 0.3rem;
        padding: 0 0.3rem 0.9rem;
        font-family: GTPressuraLight;
        font-size: 0.8rem;

        span {
          font-family: GTPressuraRegular;
          font-size: 0.9rem;
        }
      }

      .info_articles {
        margin: 1rem 0 0.8rem;
        width: 100%;

        ._center {
          text-align: center;
        }

        ._right {
          float: right;
          font-weight: bold;
          margin-left: 0.5rem;
        }

        table {
          width: 95%;
          margin: 0 auto;
          border-collapse: collapse;

          thead {
            th {
              padding: 0.2rem 0.5rem;
              font-size: 0.9rem;
              background-color: rgb(47, 51, 55);
              color: rgb(240, 248, 255);
              border: 1px solid rgb(47, 51, 55);
            }
          }

          tbody {
            tr {
              border: 1px solid rgb(47, 51, 55);

              td {
                padding: 0.2rem 0.5rem;
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      .info_footer {
        position: sticky;
        bottom: 8px;
        left: 0;
        display: block;
        width: 100%;
        margin-top: 2rem;
        @include themify($themes) {
          background-color: themed("bkgBodyContra");
        }

        .info_price {
          display: block;
          width: 100%;
          margin: 0 auto;
          padding: 0.2rem 0.6rem;
          font-size: 0.9rem;
          font-weight: bold;

          .right {
            float: right;
          }

          &._total {
            margin-bottom: 0.8rem;
            background-color: rgb(47, 51, 55);
            color: rgb(240, 248, 255);
          }
        }

        h6 {
          display: block;
          width: 100%;
          margin: 1.9rem auto 0;
          padding: 0.3rem 0.8rem;
          font-size: 0.6rem;
          font-weight: normal;
          text-align: center;
          @include themify($themes) {
            border: 1px dashed themed("textPrimary");
          }
        }
      }
    }
  }
}

._qr_code {
  svg {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: block;

    path {
      @include themify($themes) {
        stroke: themed("textPrimary");
      }

      &:first-child {
        stroke: none;
        @include themify($themes) {
          fill: themed("bkgBodyContra");
        }
      }
    }
  }
}

._bar_code {
  display: block;

  svg {
    width: 100%;
    height: 90px;
    margin: 0;

    g {
      @include themify($themes) {
        fill: themed("textPrimary") !important;
      }

      text {
        font-size: 1.2rem !important;
        font-weight: bold !important;
        font-family: "Roboto", sans-serif !important;
      }
    }
  }
}

.marco_print {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 0 1rem;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  display: block;
  background-color: rgb(255, 255, 255);
  z-index: 900;
  overflow-y: scroll;

  .content_to_print {
    margin: 0 auto;
    background-color: rgb(255, 255, 255);

    h1 {
      margin: 0.8rem auto 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: rgb(0, 0, 0);
    }

    h2 {
      margin: 0 auto 0.8rem;
      padding: 0 0.6rem;
      font-size: 1rem;
      font-weight: bold;
      color: rgb(0, 0, 0);
    }

    i {
      margin: 0 auto;
      font-size: 0.9rem;
      color: rgb(32, 32, 32);
    }

    .table {
      margin: 0;

      tr.table_title {
        background-color: rgb(255, 255, 255) !important;
        color: rgb(0, 0, 0) !important;
        font-weight: bold;
      }

      .table_row td {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0) !important;
        font-weight: normal;
      }

      .table_row.bkg_inter_line td {
        background-color: rgb(240, 240, 240) !important;
      }
    }

    .cont_firma {
      width: 100%;
      padding-left: 1rem;

      i {
        margin: 0 auto;
        font-size: 0.8rem;
      }

      p {
        color: rgb(0, 0, 0);

        sub {
          display: block;
          width: 100%;

          hr {
            margin-left: 17%;
            border: none;
            border-bottom: 1px solid rgb(0, 0, 0);
            width: 75%;
          }
        }

        &.firma {
          margin: 0.8rem auto 0;
          font-size: 1rem;
          font-weight: normal;
          color: rgb(0, 0, 0);
        }
      }

      h4 {
        margin: 0.9rem auto 0;
        font-size: 1rem;
        color: rgb(0, 0, 0);

      }
    }
  }
}
