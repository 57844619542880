.dropzone {
  width: 100%;
  height: 117px;
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("bkgInbox");
    border: 1px solid themed("colorBorderFieldset");
  }

  &.width_limit {
    max-width: 25rem;
    margin: 0 auto 2rem;
  }

  .dropzone__img {
    height: 117px;

    img {
      width: 100%;
      height: 117px;
      object-fit: contain;
    }
  }

  &.drag_over,
  .dropzone__input:hover {
    @include themify($themes) {
      background-image: linear-gradient(
        -45deg,
        themed("colorBorder") 25%,
        transparent 25%,
        transparent 50%,
        themed("colorBorder") 50%,
        themed("colorBorder") 75%,
        transparent 75%,
        transparent
      );
    }

    background-size: 30px 30px;
    animation: striped 2s linear infinite;

    @keyframes striped {
      from {
        background-position: 0 0;
      }

      to {
        background-position: 60px 30px;
      }
    }
  }
}

.dropzone__input {
  width: 100%;
  height: 100%;
  min-height: 117px;
  display: flex;
  position: absolute;
  cursor: pointer;

  &.dropzone__loading {
    align-items: center;

    .dropzone__loading_icon {
      display: flex;
      align-items: center;
      position: relative;
      width: 3.5rem;
      min-width: 3.5rem;
      height: 3.5rem;
      min-height: 3.5rem;
      margin-left: 0.5rem;

      svg {
        position: absolute;
        width: 3.5rem;
        height: 3.5rem;
        margin: 0;
        padding: 0;
      }

      i.progress {
        display: block;
        position: relative;
        width: min-content;
        margin: auto;
        font-size: 0.8rem;
      }
    }

    i.file {
      margin: 0 0.4rem;
    }
  }
}

.dropzone__drop-here {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.3rem;
    height: 2.3rem;
    margin: 0 12px;
    color: $color-additional;
  }

  p {
    font-size: 0.9rem;
    color: $color-additional;
  }
}

.dropzone__img {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .dropzone__h3 {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0.2rem;
    padding-left: 0.8rem;
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    position: relative;

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  &._voucher {
    justify-content: flex-start;
    padding: 1rem 0 1rem;
  }

  .dropzone__voucher {
    display: block;
    width: 90%;
    margin: 0.2rem auto;
    padding: 0.2rem;
    padding-left: 0.8rem;
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgInbox");
      border: 1px solid themed("colorBorderFieldset");
    }

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    .dropzone__img-delete {
      opacity: 1;
    }

    .dropzone__img-name {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    @include themify($themes) {
      background-color: themed("bkgBody");
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    @include themify($themes) {
      border: 1px solid themed("colorScrollbar");
      background-color: themed("colorScrollbar");
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background-color: themed("textPrimary");
    }
  }
}

.dropzone__img-name {
  position: absolute;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 30px 0;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s;
  opacity: 0;
  top: 10px;
  z-index: 10;
  color: rgb(240, 240, 240);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.8), 0 0 6px rgba(0, 0, 0, 0.6),
    0 0 12px rgba(0, 0, 0, 0.4);
}

.dropzone__img-delete {
  position: absolute;
  transition: all 0.3s;
  right: 6px;
  top: 8px;
  background: transparent;
  font-size: 0.8rem;
  text-transform: lowercase;
  padding: 4px 8px;
  border-radius: 6px;
  z-index: 22;
  opacity: 0;
  cursor: pointer;
  @include themify($themes) {
    color: themed("textPrimary");
    border: 1px solid themed("colorBorderFieldset");
  }

  @media screen and (max-width: 520px) {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.dropzone__file-delete {
  position: absolute;
  top: 0.6rem;
  right: 0;
  padding: 1px 6px 2px 8px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 6px;
  text-transform: lowercase;
  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("bkgInbox");
    border: 1px solid themed("colorBorderFieldset");
  }
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  background-color: rgba(25, 25, 25, 0.6);

  .load__icon-wrap {
    margin: auto;
  }

  .load__icon {
    animation: linear loading 2s infinite;
    width: 32px;
    height: 32px;
  }
}

.dropzone__alert {
  .alert__input {
    top: 145px;
    width: max-content;
    max-width: 95%;
    white-space: normal;
  }
}

.content_primary._on_popup,
.content_primary._on_details {
  .dropzone__img-delete,
  .dropzone__file-delete {
    display: none;
  }
}

.content_primary._on_details {
  .dropzone {
    height: 150px;
    background-color: transparent;

    .dropzone__img {
      height: 150px;
      pointer-events: all;

      img {
        width: calc(100% - 24px);
        height: calc(150px - 24px);
        margin: 12px;
        object-fit: contain;
      }

      &:hover {
        .dropzone__img-name {
          opacity: 0;
        }
      }
    }
  }
}

.content_primary._on_popup {
  .dropzone {
    height: 100px;
    background-color: transparent;

    .dropzone__img {
      height: 100px;
      pointer-events: all;

      img {
        width: calc(100% - 24px);
        height: calc(100px - 24px);
        margin: 12px;
        object-fit: contain;
      }

      &:hover {
        .dropzone__img-name {
          opacity: 0;
        }
      }
    }
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}
