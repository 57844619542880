.home_cont_btns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 1rem 0.5rem;

  .btn_home {
    width: 100%;
    max-width: 150px;
    margin: 12px 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    transition: all 0.3s;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:hover {
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: scale(0.92);
    }

    svg {
      width: 4rem;
      height: 4rem;
    }

    h4 {
      width: 100%;
      margin: 6px 8px 0;
      text-align: center;
    }

    @media screen and (max-width: 520px) {
      max-width: 120px;
      margin: 18px 12px;
    }
  }
}

hr {
  display: block;
  width: 50%;
  margin: 0 auto;
  opacity: 0.4;
  border: none;
  @include themify($themes) {
    border-top: 1px solid themed("colorBorderFieldset");
  }

  @media screen and (max-width: 576px) {
    width: 80%;
  }
}

.head_bar {
  position: relative;
  display: block;

  h2 {
    &._center {
      text-align: center;
    }
  }

  .head_bar-title {
    position: relative;
    display: block;
    margin: 1rem 3rem 2rem;
    font-size: 1.5rem;
    text-align: center;

    &._capitalize {
      text-transform: capitalize;
    }

    &._left {
      margin: 0.3rem 0 0.9rem;
      text-align: left;
    }

    span {
      padding: 0 0 0.2rem 1rem;
      border-radius: 12px;

      &._aceptado sup,
      &._aceptado {
        background-color: rgb(100, 255, 0);
        color: rgb(60, 60, 60);
      }

      &._rechazado sup,
      &._rechazado {
        background-color: rgb(255, 50, 0);
        color: rgb(240, 240, 240);
      }

      &._cierre {
        position: absolute;
        top: 2.9rem;
        left: 0.5rem;
        font-size: 1.2rem;
        font-weight: normal;
        font-family: GTPressuraLight;
      }

      sup {
        font-size: 1.4rem;
        padding: 0.1rem 1rem 0;
        border-radius: 12px;
      }
    }

    small {
      display: block;
      padding: 1rem 0 2rem;
      font-weight: lighter;
    }
  }

  .title_and_resume {
    font-size: 1.3rem;
    font-weight: bold;

    span {
      display: block;
      font-size: 0.9rem;
      font-weight: normal;
      opacity: 0.8;
    }
  }

  &.with_margin {
    height: 30px;
    padding: 0;
    margin: 0;

    .search_bar {
      position: absolute;
      top: 14px;
      right: 0;
    }
  }

  @media screen and (max-width: 520px) {
    h2:not(.head_bar-title) {
      display: none;
    }

    .head_bar-title {
      margin: 0.6rem 1rem 3rem;
      text-align: left;
      white-space: pre-line;
    }

    &.with_margin {
      height: 60px;

      .search_bar {
        top: 16px;
      }
    }
  }
}

.head_bar_sticky {
  position: sticky;
  top: 74px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: 2.6rem;
  padding: 0.6rem 1rem;
  z-index: 94;
  border-radius: 12px;
  @include themify($themes) {
    border: 1px solid themed("colorBorderFieldset");
    background-color: themed("bkgBodyContra");
  }

  .in_row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: fit-content;
  }

  img {
    margin: 0;
    padding: 0 12px;
    width: 200px;
    height: 40px;
    object-fit: contain;
    border-radius: 8px;

    &.dark {
      background-color: #2a2a31;
    }

    &.light {
      background-color: #f0f0f0;
    }
  }

  h2 {
    display: inline-block;
    margin: 0 0 1rem;
    padding: 0 2rem 0 0;
    font-size: 1.2rem;
  }

  h3 {
    min-width: 190px;
    margin-bottom: 0;
    display: inline-block;
    font-size: 1.1rem;
    text-align: right;
  }

  button {
    margin: 0 0.4rem 0 1.4rem !important;
  }

  .checkbox_top_right {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 6px;
    overflow: hidden;

    input {
      width: 2rem !important;
      height: 2rem;
      opacity: 0;
      cursor: pointer;
    }

    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 520px) {
    top: 60px;
    margin-left: 0;

    .in_row {
      display: flex;
      flex-flow: column wrap;

      button {
        margin: 1rem 0.4rem 0 !important;
        padding-right: 1.9rem !important;
      }
    }

    h2 {
      padding: 0 2.5rem 0 0;
    }

    .checkbox_top_right {
      width: 2.5rem;
      height: 2.5rem;

      input {
        width: 2.5rem !important;
        height: 2.5rem;
      }

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}

.search_bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;

  input {
    padding: 6px 32px 8px 8px;
    border-radius: 8px;
    @include themify($themes) {
      border: 1px solid themed("colorBorderFieldset");
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  @media screen and (max-width: 520px) {
    position: relative;
    left: auto;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    input {
      padding-right: 2.8rem !important;
    }

    svg {
      width: 2.7rem;
      height: 2.7rem;
    }
  }
}

#root .popup,
.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  cursor: default;
  @include themify($themes) {
    background-color: themed("bkgShadow");
  }

  .popup_window {
    position: fixed;
    display: block;
    padding: 0;
    border-radius: 12px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.9);
    @include themify($themes) {
      background-color: themed("bkgBodyContra");
    }

    .popup_title {
      width: 100%;
      position: relative;
      font-size: 1.3rem;
      margin: 0;
      padding: 0.3rem 1rem;
      cursor: move;
      @include themify($themes) {
        color: themed("textPrimary");
        border-bottom: 1px solid themed("colorBorderFieldset");
      }

      svg {
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
        width: 2rem;
        height: 2rem;
        padding: 4px;
        cursor: pointer;
      }
    }

    .popup_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0.6rem;
    }

    &.height_fixed {
      height: auto;
      min-height: 400px;
      overflow: hidden;

      .popup_content {
        padding: 0;
      }

      @media screen and (max-width: 520px) {
        height: calc(100% - 90px);
      }
    }
  }
}

.calendar {
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  z-index: 91;
  @include themify($themes) {
    border: 1px solid themed("colorBorderFieldset");
    background-color: themed("bkgBodyContra");
  }

  .calendar_content {
    .arrow_left,
    .arrow_right {
      width: 1.6rem;
      height: 1.6rem;
      padding: 0;
      margin: 0;
      top: 0.2rem;
    }

    .arrow_left {
      left: -0.2rem;
      right: auto;
    }

    .arrow_right {
      left: auto;
      right: -0.2rem;
    }

    .calendar_year {
      position: relative;

      @include themify($themes) {
        border-bottom: 1px solid themed("colorBorderFieldset");
      }

      h3 {
        font-size: 1rem;
        padding: 0.2rem 2rem;
        margin: 0;
        text-align: center;
      }
    }

    .calendar_month {
      position: relative;

      h4 {
        font-size: 1.2rem;
        padding: 0.2rem 2rem;
        margin: 0;
        text-align: center;
      }
    }

    .calendar_days {
      display: block;
      width: max-content;

      p {
        display: block;
        width: max-content;
        padding: 0 0.4rem;

        &:first-child {
          background-color: rgba(95, 158, 160, 0.2);
        }

        &:last-child {
          padding-bottom: 0.2rem;
          @include themify($themes) {
            background-color: themed("bkgBody");
          }
        }

        span {
          display: inline-block;
          width: 2rem;
          padding: 6px;
          text-transform: lowercase;
          font-size: 0.9rem;
          text-align: center;

          &.names {
            font-size: 0.8rem;
          }

          &.previo {
            opacity: 0.5;
          }

          &.previo,
          &.current {
            cursor: pointer;
            border-radius: 50%;

            &:hover {
              @include themify($themes) {
                background-color: themed("colorHover");
              }
            }

            &.selected,
            &:active {
              @include themify($themes) {
                background-color: themed("colorSelectList");
              }
            }
          }
        }
      }
    }
  }
}

.radio_goup {
  position: relative;
  display: block;
  width: 100%;

  label {
    width: max-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;
    padding: 0.4rem;
    cursor: pointer;
  }

  input {
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 2.6rem 0 0.9rem;
  }

  svg {
    position: absolute;
    left: 2.3rem;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 0.3rem;
  }

  .contain_alert {
    .alert__input {
      top: 4.5rem;
      left: 0.6rem;
    }
  }

  &._in_row {
    margin: 0.65rem 0 0.4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    label {
      position: relative;
      padding-right: 0.8rem;
      border-radius: 8px;
      @include themify($themes) {
        border: 1px solid themed("colorBorderFieldset");
      }
    }

    input {
      margin: 0 2.6rem 0 0.2rem;
    }

    svg {
      left: 1.6rem;
    }
  }
}

.caja {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &._to_left {
    align-items: flex-start;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  ._rotate {
    animation: rotation 1400ms infinite linear;
  }

  .caja_to_rigth {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .caja_icon_renew {
      width: 2rem;
      height: 2rem;
      margin: 0 1.2rem 1rem;
      cursor: pointer;

      &._to_table {
        position: absolute;
        top: 0.15rem;
        right: 0.6rem;
        margin-right: 0;
        border-radius: 50%;
        z-index: 10;
        @include themify($themes) {
          color: themed("textPrimary") !important;
          background-color: themed("bkgInbox");
        }
      }
    }
  }

  .caja_icon_renew {
    &._in_tr {
      position: absolute;
      top: 0.2rem;
      right: 0.3rem;
      margin-right: 0;
      border-radius: 50%;

      @include themify($themes) {
        color: themed("textPrimary") !important;
        background-color: themed("bkgInbox");
      }
    }
  }

  .caja_totales {
    display: inline-block;
    width: min-content;
    margin: 0.3rem 0;
    padding: 0 0.6rem;
    font-size: 1rem;
    text-align: right;
    white-space: nowrap;
    @include themify($themes) {
      border-bottom: 1px solid themed("colorBorderFieldset");
    }

    span {
      font-size: 0.8rem;
      font-weight: normal;
      margin-right: 0.5rem;
    }

    samp {
      display: inline-block;
      min-width: 6rem;
      margin-left: 1rem;
      font-size: 0.9rem;
      font-weight: normal;
      text-align: right;
    }
  }

  .caja_contiene_tabla {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: -24px;
    @include themify($themes) {
      border: 1px solid themed("colorBorderFieldset");
    }

    &._auto_width {
      width: min-content;
    }

    &._auto_height {
      margin-bottom: 0;
    }

    .caja_tabla {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 80vh;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        @include themify($themes) {
          background-color: themed("bkgBody");
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        @include themify($themes) {
          border: 1px solid themed("colorScrollbar");
          background-color: themed("colorScrollbar");
        }
      }

      &::-webkit-scrollbar-thumb:hover {
        @include themify($themes) {
          background-color: themed("textPrimary");
        }
      }

      .table {
        margin-top: 0;
        position: relative;
        border-left: none;
        border: none;

        .table_title {
          position: sticky;
          top: 0;
          z-index: 3;
          box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);

          th {
            height: 36px;

            @include themify($themes) {
              background-color: themed("bkgBody");
              border-bottom: 1px solid themed("colorBorderFieldset");
            }

            &.colum_title {
              padding-right: 0;
              display: flex;
              flex-flow: row nowrap;

              .form_group {
                margin: 0 0 0 1rem;

                .form_group-field {
                  margin: -4px 0;
                  border: none;
                  border-radius: 0;
                  @media screen and (min-width: 521px) {
                    @include themify($themes) {
                      border-left: 1px solid themed("colorBorderFieldset");
                    }
                  }
                }
              }

              @media screen and (max-width: 520px) {
                padding-left: 0;

                .form_group {
                  margin: 0;
                }
              }
            }

            &._dias {
              text-align: center;
            }

            &.colum_icons {
              padding: 0 1rem;

              svg {
                margin: auto 0.3rem -8px;
              }
            }

            &:last-child {
              border-radius: 0;
            }

            @media screen and (max-width: 520px) {
              &:last-child {
                border-radius: 0;
              }
            }
          }
        }

        td {
          cursor: default;
          white-space: pre-line;
        }

        .colum_title {
          width: 22rem;
          min-width: 22rem;
          position: sticky;
          left: 0;
          z-index: 2;
        }

        .colum_icons {
          width: min-content;
          padding: 0.4rem 1rem;
          white-space: nowrap;
          text-align: right;

          svg {
            margin: auto 0.3rem -4px;
            cursor: pointer;
          }

          &._text_left {
            svg:nth-last-child(2) {
              margin-left: 1rem;
            }
          }
        }

        ._dias {
          width: 5rem;
          min-width: 5rem;
          white-space: nowrap;
          text-align: right;
        }

        ._force_width {
          width: 100% !important;
          max-width: 100% !important;
        }

        @media screen and (max-width: 520px) {
          .colum_title {
            width: 40vw;
            min-width: 40vw;
            max-width: 40vw;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.content_icons {
  padding: 3rem 6rem;

  h2 {
    display: block;
    text-align: center;
  }

  p {
    display: inline-block;
    text-align: center;
    margin: 1rem;
  }
  svg {
    width: 3rem;
    height: 3rem;
  }
}

.cont_edges {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  .edges_list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 350px;
    max-height: 90vh;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    @include themify($themes) {
      border: 1px solid themed("colorBorderFieldset");
      background-color: themed("bkgBodyContra");
    }

    svg.close {
      top: 0.2rem !important;
    }

    span {
      margin: 0.2rem 0 0.4rem;
      padding: 0.2rem 1rem;
      font-size: 1rem;
      border-radius: 8px;
      cursor: pointer;
      @include themify($themes) {
        border: 1px solid themed("bkgBody");
        background-color: themed("bkgBody");
      }

      &:hover {
        @include themify($themes) {
          border: 1px solid themed("colorLinck");
          color: themed("colorLinck");
        }
      }
    }

    .scroll_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 1.8rem 1rem 0.5rem;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        @include themify($themes) {
          background-color: themed("bkgBodyContra");
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 1px solid rgb(100, 100, 100);
        background-color: rgb(100, 100, 100);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(230, 230, 230);
      }

      @media screen and (max-width: 520px) {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
