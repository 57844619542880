body {
  margin: 0;
  padding: 0;
  font-family: GTPressuraRegular;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("bkgBody");
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(35, 35, 41);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid rgb(100, 100, 100);
    background-color: rgb(100, 100, 100);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(230, 230, 230);
  }

  @media screen and (max-width: 520px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

*:focus,
*:active,
*:active:focus {
  outline: none;
}

*::-webkit-scrollbar {
  display: none;
}

.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 2000;

  .load__icon-wrap {
    margin: auto;
  }

  .load__icon-wrap {
    position: relative;

    .load__icon-progress {
      width: 150px;
      height: 150px;
      position: absolute;
      top: 50px;
      left: 0;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
      color: rgb(0, 0, 0);
    }
  }
}
