tbody,
tr,
th,
td,
thead,
tfoot {
  border: 0 solid;
  border-color: inherit;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border-color: gray;
  border-radius: 12px;
  width: 100%;
  max-width: 100vw;
  margin: 2rem 0 0;
  @include themify($themes) {
    border: 1px solid themed("colorBorderFieldset");
  }

  &.apendice {
    max-width: 25rem;
    margin-top: 0;
    margin-bottom: -2rem;

    .table_title {
      th {
        white-space: nowrap;
      }
    }

    .icon_img {
      width: 1.7rem;
      height: 1.7rem;
      float: right;
      cursor: pointer;
    }

    .icon_edit {
      width: 1.5rem;
      height: 1.5rem;
      float: right;
      cursor: pointer;
      margin-left: 0.4rem;
    }

    .icon_delete {
      width: 1.5rem;
      height: 1.5rem;
      float: right;
      cursor: pointer;
      margin-left: 0.2rem;
    }

    @media screen and (max-width: 520px) {
      max-width: 90vw;
    }
  }

  .table_row {
    position: relative;
    font-size: 1rem;
    cursor: pointer;

    &.no_pointer {
      cursor: default !important;
    }

    td {
      @include themify($themes) {
        color: themed("textPrimary");
        background-color: themed("bkgBodyContra");
      }

      &.td_with_forms {
        white-space: nowrap;

        input {
          max-width: 7rem;
          padding: 0.3rem 0.6rem !important;
          border-radius: 8px;
          @include themify($themes) {
            border: 1px solid themed("colorBorderFieldset");
          }
        }

        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
          -webkit-appearance: textfield;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
        }

        .btn_primary {
          margin: 0;
          padding: 0.3rem 0.6rem;
          font-size: 0.9rem;
          font-weight: normal;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

          &.green {
            margin-right: 0.6rem;
            color: rgb(70, 70, 70);
            background-color: rgb(100, 255, 0);

            &:before {
              background-color: darken(rgb(100, 255, 0), 12%);
            }
          }

          &.green_aux {
            margin-right: 0.6rem;
            margin-bottom: 0.6rem;
            color: rgb(70, 70, 70);
            background-color: rgb(100, 200, 100);

            &:before {
              background-color: darken(rgb(100, 200, 100), 8%);
            }
          }

          &.blue {
            margin-right: 0.6rem;
            color: rgb(255, 255, 255);
            background-color: rgb(0, 102, 255);

            &:before {
              background-color: darken(rgb(0, 102, 255), 10%);
            }
          }

          &.blue_aux {
            margin-right: 0.6rem;
            margin-bottom: 0.6rem;
            color: rgb(255, 255, 255);
            background-color: rgb(190, 80, 255);

            &:before {
              background-color: darken(rgb(190, 80, 255), 20%);
            }
          }

          &.orange {
            color: rgb(60, 60, 60);
            background-color: rgb(255, 165, 0);

            &:before {
              background-color: darken(rgb(255, 165, 0), 10%);
            }
          }
        }

        svg {
          width: 2rem;
          height: 2rem;
          margin: -0.2rem 0 -0.4rem;
        }

        span.msg {
          font-size: 0.9rem;
          font-weight: lighter;
        }

        @media screen and (max-width: 520px) {
          white-space: normal;

          pre {
            margin: 0.9rem 0;

            span {
              &.width_fixed {
                display: inline-block;
                text-align: right;
                width: 6rem;
                margin-right: 0.5rem;
                margin-bottom: -0.2rem;
              }

              &.msg {
                font-size: 1.2rem;
                font-weight: bold;
              }
            }

            input {
              max-width: 7rem;
              padding: 0.3rem 0.6rem !important;
              border-radius: 8px;
            }

            &.row_center {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-evenly;

              .btn_primary {
                margin: 1rem 0 0;
                padding: 0.6rem 0.9rem;
                font-size: 1.1rem;
              }

              svg {
                width: 3rem;
                height: 3rem;
              }
            }
          }
        }
      }

      &.td_menu {
        z-index: 4;
      }

      &.td_info {
        padding: 0;
        position: relative;

        .info_name {
          display: block;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;

          p {
            margin: 0;
            padding: 0 0 0 2.3rem;
          }

          svg.icon_card {
            position: absolute;
            top: 0.3rem;
            left: 0.4rem;
            width: 1.3rem;
            height: 1.3rem;
          }

          &._product {
            p {
              padding-left: 1.8rem;
            }

            svg.icon_card {
              left: 0.2rem;
            }
          }
        }

        .info_card {
          position: absolute;
          top: 0.1rem;
          left: 0.4rem;
          z-index: 91;
          cursor: auto;
          padding: 0.6rem 0.8rem;
          border-radius: 8px;
          box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
          @include themify($themes) {
            background-color: themed("colorCorporate");
            border: 1px solid themed("colorBorderFieldset");
          }

          svg.icon_loading {
            pointer-events: none;
            margin-bottom: -0.4rem;
          }

          h5 {
            margin: 0;
            padding: 0;
            font-size: 1.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            &.info_address {
              font-size: 0.7rem;
            }

            &.info_orders {
              padding: 0.2rem 0 0.4rem;
              font-size: 1rem;
            }

            &.info_nota {
              margin-top: 0.9rem;
              max-width: 25rem;
              font-size: 0.9rem;
              overflow: hidden;
              white-space: pre-line;
              text-overflow: ellipsis;
            }

            &.info_product {
              margin-top: 0.2rem;
              max-width: 25rem;
              font-size: 0.9rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              span {
                font-family: GTPressuraLight;
              }
            }
          }

          i {
            &.info_email {
              display: block;
              font-size: 0.8rem;
              font-weight: lighter;
              line-height: 1;
              @include themify($themes) {
                color: themed("colorLinck");
              }
            }

            &.info_phone {
              display: block;
              font-size: 0.9rem;
              font-weight: lighter;
            }
          }
        }
      }

      sup {
        @include themify($themes) {
          color: themed("colorLinck");
        }
      }
    }

    td:not(.td_menu) {
      &.cont_city {
        span {
          display: block;
          font-size: 1rem;

          a {
            color: rgb(153, 126, 251);
            text-decoration: none;
          }

          &.with_selects {
            display: flex;
            flex-flow: row nowrap;
            padding: 0;
            margin: -4px 0;

            .form_group {
              width: 160px;
              margin: 0 8px 0 0;

              input {
                padding: 3px 22px 3px 8px !important;
              }

              .input {
                svg {
                  width: 1.4rem;
                  height: 2rem;
                  padding: 2px !important;
                }
              }

              @media screen and (max-width: 520px) {
                width: 130px;
              }
            }

            button {
              margin-left: 0 !important;
            }
          }
        }

        strong {
          font-size: 0.9rem;
          font-weight: bold;
          margin-left: 0.4rem;
        }

        button {
          min-width: 4.5rem;
          padding: 0.1rem 0.4rem;
          margin-left: 0.4rem;
          font-size: 0.9rem;
          background: transparent;
          border-radius: 8px;
          cursor: pointer;
          @include themify($themes) {
            color: themed("textPrimary");
            border: 1px solid themed("colorBorderFieldset");
          }

          svg {
            height: 1rem;
          }
        }

        ._error {
          font-size: 1rem;
          font-weight: bold;
          @include themify($themes) {
            color: themed("colorError");
          }
        }
      }
    }

    &.bkg_inter_line {
      td:not(.td_menu) {
        @include themify($themes) {
          //   background-color: themed("colorInterLine");
          background-color: darken(themed("bkgBodyContra"), 4%);
        }
      }
    }

    &.bkg_category_line {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);

        @include themify($themes) {
          background-color: themed("colorCategory");
        }
      }

      &:hover {
        td:not(.td_menu) {
          @include themify($themes) {
            background-color: themed("colorCategory");
          }
        }
      }

      .colum_title {
        h4 {
          margin: 0;
          padding: 0 1rem 0.5rem;
          font-size: 1.2rem;
          text-transform: capitalize;
        }
      }
    }

    &:hover {
      td:not(.td_menu) {
        @include themify($themes) {
          background-color: themed("colorHoverList");
        }
      }
    }

    &.bkg_select {
      td:not(.td_menu) {
        @include themify($themes) {
          background-color: themed("colorSelectList");
        }
      }
    }

    &.bkg_delivery {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(242, 255, 0);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(242, 255, 0), 9%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: lighten(rgb(242, 255, 0), 16%);
        }
      }
    }

    &.bkg_aceptado {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(100, 255, 0);

        .info_card {
          background-color: rgb(100, 255, 0);
        }

        sup {
          color: rgb(60, 60, 60);
        }
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(100, 255, 0), 10%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: rgb(255, 255, 38);
        }
      }
    }

    &.bkg_aceptado_cambio {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(100, 200, 100);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(100, 200, 100), 4%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(100, 200, 100), 8%);
        }
      }
    }

    &.bkg_pendiente {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(255, 165, 0);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(255, 165, 0), 9%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: rgb(255, 175, 0);
        }
      }
    }

    &.bkg_rechazado {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        background-color: rgb(255, 50, 0);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(255, 50, 0), 9%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: rgb(255, 80, 38);
        }
      }
    }

    &.bkg_pagado {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        background-color: rgb(0, 102, 255);

        .info_card {
          background-color: rgb(0, 102, 255);
        }

        sup {
          color: rgb(240, 240, 240);
        }
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 102, 255), 9%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 102, 255), 12%);
        }
      }
    }

    &.bkg_pagado_pendiente {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        background-color: rgb(190, 80, 255);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(190, 80, 255), 16%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(190, 80, 255), 20%);
        }
      }
    }

    &.bkg_recibido {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(0, 220, 220);
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 220, 220), 4%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 220, 220), 6%);
        }
      }
    }

    &.bkg_from_local {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        background-color: rgb(70, 130, 180);

        .info_card {
          background-color: rgb(70, 130, 180);
        }

        sup {
          color: rgb(240, 240, 240);
        }
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(70, 130, 180), 9%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(70, 130, 180), 12%);
        }
      }
    }

    &.bkg_por_validar {
      td:not(.td_menu) {
        color: rgb(240, 240, 240);
        background-color: rgb(0, 98, 164);

        .info_card {
          background-color: rgb(0, 98, 164);
        }

        sup {
          color: rgb(240, 240, 240);
        }
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 98, 164), 6%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(0, 98, 164), 8%);
        }
      }
    }
    
    &.bkg_reabierto {
      td:not(.td_menu) {
        color: rgb(60, 60, 60);
        background-color: rgb(255, 255, 0);

        .info_card {
          background-color: rgb(255, 255, 0);
        }

        sup {
          color: rgb(60, 60, 60);
        }
      }

      &.bkg_inter_line {
        td:not(.td_menu) {
          background-color: darken(rgb(255, 255, 0), 6%);
        }
      }

      &:hover {
        td:not(.td_menu) {
          background-color: darken(rgb(255, 255, 0), 8%);
        }
      }
    }

    &:last-child {
      &::after {
        border-radius: 0 0 12px 12px;
      }

      td:first-child {
        overflow: hidden;
      }
    }
  }

  tr {
    &.table_title {
      position: relative;
      font-size: 1.1rem;

      @include themify($themes) {
        color: themed("textPrimary");
        background-color: themed("bkgBody");
      }

      th {
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: 12px;
        }

        @media screen and (max-width: 520px) {
          white-space: normal;
        }
      }

      .title_and_resume {
        display: block;
        font-size: 1.1rem;
        font-weight: bold;

        span {
          display: block;
          font-size: 0.8rem;
          font-weight: normal;
          opacity: 0.8;
          padding-left: 2.4rem;
        }

        svg {
          margin-bottom: -1.2rem;
        }
      }
    }

    th:first-child {
      border-radius: 12px 0 0 0;
    }

    th:last-child {
      border-radius: 0 12px 0 0;

      @media screen and (max-width: 520px) {
        border-radius: 12px 12px 0 0;
      }
    }

    &.radius_bottom {
      th:first-child {
        border-bottom-left-radius: 12px;
      }

      th:last-child {
        border-bottom-right-radius: 12px;
      }

      &::after {
        border-radius: 12px;
      }
    }

    &:last-child {
      td:first-child {
        border-radius: 0 0 0 12px;
      }

      td:nth-last-child(2) {
        border-radius: 0 0 12px 0;

        @media screen and (max-width: 520px) {
          border-radius: 0 0 12px 12px;
        }
      }
    }
  }

  th,
  td {
    padding: 4px 12px;
    text-align: left;

    &.cont_checkbox {
      cursor: pointer;
      white-space: nowrap;

      input[type="checkbox"] {
        width: 1.1rem !important;
        height: 1.1rem !important;
        margin-right: 0.7rem;
        pointer-events: none;
      }

      span {
        width: 1.1rem;
        height: 1.1rem;
        margin-right: 0.7rem;
      }

      ._error {
        margin-left: -0.2rem;
        margin-right: 0.5rem;
        @include themify($themes) {
          color: themed("colorError");
        }
      }

      ._correcto {
        margin-left: -0.2rem;
        margin-right: 0.5rem;
        color: rgb(0, 195, 23);

        &._to_print {
          width: 1.1rem !important;
          height: 1.1rem !important;
          margin-left: 0;
          margin-right: 0.7rem;
          color: rgb(0, 36, 195);
          background-color: rgb(248, 248, 248);
          border: 1px solid rgb(99, 99, 99);
          border-radius: 3px;
          stroke-width: 2px;
          stroke: rgb(0, 36, 195);
        }
      }

      &._with_frame {
        input[type="checkbox"] {
          display: inline-block;
          transform: translateY(3px);
        }

        svg {
          margin-bottom: -4px;

          color: white !important;
        }
      }
    }

    &.cont_checkbox_second {
      position: relative;
      cursor: pointer;
      padding-left: 2.4rem;

      input[type="checkbox"] {
        position: absolute;
        top: 6px;
        left: 8px;
        width: 1.1rem !important;
        height: 1.1rem !important;
        margin-right: 0.7rem;
        pointer-events: none;
      }

      &.disable {
        cursor: not-allowed;

        input[type="checkbox"] {
          opacity: 0.4;
        }
      }
    }
  }

  th {
    position: relative;
    @include themify($themes) {
      border-right: 1px solid themed("colorBorderFieldset");
    }

    &:last-child {
      border-right: 0 solid;
      border-right-color: inherit;
    }

    svg {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin: 0 12px -6px 0;
      cursor: pointer;
    }
  }

  td {
    @include themify($themes) {
      border-top: 1px solid themed("colorBorderFieldset");
      border-right: 1px solid themed("colorBorderFieldset");
    }

    b.tag {
      display: inline-block;
      margin: 0;
      padding: 0.1rem 0.4rem;
      font-size: 0.8rem;
      font-weight: bold;
      border-radius: 8px;
      color: rgb(250, 250, 250);
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:last-child {
      padding: 0 !important;
      border: 0 solid;
      border-color: inherit;
    }

    &:nth-last-child(2) {
      border-right: 0 solid;
      border-right-color: inherit;
    }
  }

  @media screen and (max-width: 520px) {
    margin: 1rem 0 0;

    .table_row {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      pre {
        font-family: GTPressuraLight;
        font-size: 0.9rem;
        font-weight: lighter;

        span {
          margin-left: 0.4rem;
          font-size: 1rem;
          font-weight: bold;
        }

        b {
          font-family: GTPressuraBold;
        }
      }

      i {
        font-family: GTPressuraLight;
        font-size: 0.8rem;
        font-weight: lighter;
        float: right;
      }
    }

    td {
      max-width: 86vw;
      font-size: 1.1rem;
      overflow: hidden;

      span {
        overflow: hidden;
        white-space: pre-line;
        text-overflow: ellipsis;

        &.bottom_left {
          font-family: GTPressuraLight;
          font-size: 0.8rem;
          font-weight: lighter;
          margin: 0;
          padding: 0;
        }
      }

      button {
        min-width: 4.5rem;
        padding: 0.1rem 0.4rem;
        margin-left: 0.4rem;
        font-size: 0.9rem;
        background: transparent;
        border-radius: 8px;
        cursor: pointer;
        @include themify($themes) {
          color: themed("textPrimary");
          border: 1px solid themed("colorBorderFieldset");
        }

        svg {
          height: 1rem;
        }
      }

      strong {
        font-size: 1rem;
        font-weight: bold;

        &._error {
          @include themify($themes) {
            color: themed("colorError");
          }
        }
      }

      pre.cont_checkbox {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        input[type="checkbox"] {
          width: 1.1rem !important;
          height: 1.1rem !important;
          margin-right: 0.7rem;
          pointer-events: none;
        }

        span {
          margin-right: 0.7rem;
        }

        ._error {
          margin-left: -0.2rem;
          margin-right: 0.5rem;
          @include themify($themes) {
            color: themed("colorError");
          }
        }

        ._correcto {
          margin-left: -0.2rem;
          margin-right: 0.5rem;
          color: rgb(0, 195, 23);
        }

        &._with_frame {
          input[type="checkbox"] {
            display: inline-block;
          }

          svg {
            color: white !important;
          }
        }
      }
    }
  }
}

.count_list {
  margin: 1rem 0 -1rem;
  padding: 0 1rem;
  font-size: 1.2rem;
}

.msg_lazy_load {
  margin-top: 1rem;
  text-align: center;

  &._left {
    text-align: left;
  }

  &._right {
    text-align: right;
  }

  &._alone {
    margin: 3rem 0;

    @media screen and (max-width: 520px) {
      text-align: center;
    }
  }
}

.table th {
  &:has(> .filter_floating) {
    min-width: 8rem;
    padding-right: 32px;
  }

  .filter_floating {
    position: absolute;
    right: 0;
    top: 0;
    width: 148px;
    text-align: right;
    z-index: 91;
    border-radius: 8px;

    &._open {
      position: fixed;
      right: -1px;
      top: -1px;
      box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
      @include themify($themes) {
        background-color: themed("colorCorporate");
        border: 1px solid themed("colorBorderFieldset");
      }

      &:has(> .form_group) {
        width: 300px;
      }

      .form_group {
        width: calc(100% - 24px);
        margin: 0.7rem 12px 12px;

        .form_group-label {
          display: none;
        }
      }
    }

    &:has(> .filter_content) {
      width: 200px;
    }

    .filter_content {
      display: block;

      .form_group {
        width: calc(100% - 24px);
        margin: 1rem 12px;
      }

      button {
        display: block;
        margin: 8px auto;
      }
    }

    .filter_title {
      padding: 0;
      font-size: 1.2rem;
      margin: 8px 1rem 0 12px;
      height: min-content;
      float: left;
    }

    svg {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin: 0;
      cursor: pointer;
      @include themify($themes) {
        color: themed("textPrimary") !important;
      }

      &.filter_icon {
        margin: 6px;
      }

      &._check {
        color: green;
      }

      path {
        pointer-events: none;
      }
    }

    ul {
      list-style: none;
    }

    li {
      padding: 0.3rem 0.6rem;
      white-space: nowrap;
      cursor: pointer;

      &:last-child {
        margin-bottom: 1rem;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed("bkgBtnsHover");
        }
      }

      svg {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0 -0.3rem 8px;
      }

      span {
        font-size: 1rem;
        font-weight: normal;
        white-space: nowrap;
      }
    }

    @media screen and (max-width: 520px) {
      border-radius: 12px;

      .filter_title {
        font-size: 1.2rem;
      }

      li {
        padding: 0.5rem 0.6rem;

        svg {
          width: 1.7rem;
          height: 1.7rem;
          margin: 0 0 -0.5rem 8px;
        }

        span {
          font-size: 1.1rem;
        }
      }
    }
  }
}
