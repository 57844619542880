.content_modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.modal_img {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0;
  cursor: auto;
  z-index: 110;

  svg {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 24px;
    height: 24px;
    padding: 0;
    color: rgb(255, 255, 255);
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.8));
    cursor: pointer;
  }

  img {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    object-fit: scale-down;
    margin: 0;
  }

  .modal_bkg-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
    cursor: auto;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @media screen and (max-width: 520px) {
    svg {
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
    }
  }
}
