.menu {
  border-radius: 8px;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.6);
  display: block;
  max-width: 220px;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  z-index: 91;

  @include themify($themes) {
    background-color: themed("colorCorporate");
    border: 1px solid themed("colorBorderFieldset");
  }

  ul {
    list-style: none;
  }

  li {
    padding: 0.3rem 0.9rem;
    cursor: pointer;
    white-space: nowrap;

    &:first-child {
      margin-top: 1rem;
    }

    &:last-child {
      margin-bottom: 1rem;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed("bkgBtnsHover");
      }
    }

    &.unable {
      opacity: 0.5;
      cursor: not-allowed;
    }

    svg {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 8px -0.3rem 0;
    }

    span {
      font-size: 1rem;
      white-space: nowrap;
    }

    &.multi_line {
      strong {
        margin-left: 2.2rem;
      }

      svg {
        margin-bottom: -1rem;
      }
    }
  }

  @media screen and (max-width: 520px) {
    li {
      padding: 0.5rem 0.8rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 8px -0.3rem 0;
      }

      span {
        font-size: 1.1rem;
      }
    }
  }
}

.back_float {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 90;

  &._blur {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    z-index: -1;
  }
}
