header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 100;
  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("colorCorporate");
  }

  .menu_logo {
    position: absolute;
    width: 120px;
    height: 70px;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: center;
    @include themify($themes) {
      background-image: themed("logoCard");
    }
  }

  .menu_nav {
    width: min-content;
    margin-right: 12px;
    display: flex;
    flex-flow: row nowrap;
    transition: all 0.3s;

    .item_group {
      position: relative;
      display: block;
      width: max-content;
      height: min-content;
      margin: 16px 8px 6px;
      list-style: none;
      border-radius: 12px;
      box-shadow: none;
      transition: all 0.3s;
      overflow: hidden;
      @include themify($themes) {
        background-color: themed("colorCorporate");
      }

      &:hover {
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
        @include themify($themes) {
          background-color: themed("bkgBody");
        }

        .item_link {
          display: block;
        }

        .item_group-title {
          margin-bottom: 14px;
        }
      }
    }

    .item_link-icon {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      width: min-content;
      height: min-content;
      margin: 16px 8px 6px;
      list-style: none;
      border-radius: 12px;
      text-decoration: none;
      box-shadow: none;
      transition: all 0.3s;
      overflow: hidden;
      @include themify($themes) {
        background-color: themed("colorCorporate");
        color: themed("textPrimary");
      }

      &:hover {
        @include themify($themes) {
          background-color: themed("bkgBody");
        }

        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
      }

      span {
        display: block;
        padding: 6px 14px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
        transition: all 0.3s;
        @include themify($themes) {
          color: themed("textPrimary");
        }
      }
    }

    .item_group-icon {
      display: none;
    }

    .item_group-title {
      display: block;
      padding: 6px 14px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
      transition: all 0.3s;
      @include themify($themes) {
        color: themed("textPrimary");
      }
    }

    .item_link {
      display: none;
      transition: all 0.3s;
      overflow: hidden;

      a {
        display: inline-block;
        width: 100%;
        padding: 4px 14px;
        line-height: 1.8;
        text-decoration: none;
        white-space: nowrap;
        @include themify($themes) {
          color: themed("textPrimary");
        }

        &:hover {
          @include themify($themes) {
            background-color: themed("bkgBtnsHover");
          }
        }
      }
    }

    .item_link-active {
      @include themify($themes) {
        background-color: themed("bkgBtnsHover");
      }
    }
  }

  .menu_profile {
    display: block;
    min-width: 120px;

    .menu_profile_avatar {
      position: relative;
      display: inline-block;
      width: max-content;
      height: 70px;
      margin-right: 4px;
      cursor: pointer;

      h4 {
        display: inline-block;
        width: 100%;
        padding: 0;
        margin: 18px 0 0;
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i {
        display: block;
        position: relative;
        margin: 0 1.5rem 0 0.5rem;
        font-size: 0.8rem;
        text-align: right;
        white-space: nowrap;
      }

      .menu_profile_img {
        width: 36px;
        height: 36px;
        float: left;
        margin: -4px 8px 0;
      }

      .menu_profile_arrow {
        float: right;
        margin: 2px 2px 0 0;
      }
    }

    .submenu {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 200;
      background-color: transparent;

      &._opened {
        display: block;
      }

      .submenu_content {
        display: flex;
        flex-direction: column;
        float: right;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
        border-radius: 0 0 0 6px;

        @include themify($themes) {
          background-color: themed("colorCorporate");
        }

        a {
          font-size: 1.1rem;
          text-decoration: none;
          @include themify($themes) {
            color: themed("textPrimary");
          }
        }

        .menu_profile_avatar {
          padding-right: 12px;
          margin-bottom: 12px;
          @include themify($themes) {
            border-bottom: 1px solid themed("colorBorderFieldset");
          }
        }

        .submenu_item {
          display: inline;
          padding: 8px 12px;
          white-space: nowrap;
          cursor: pointer;
        }

        .submenu_icon {
          width: 1.1rem;
          height: 1.1rem;
          margin: 0 6px -4px 0;
        }

        ._version {
          padding: 0;
          font-size: 0.7rem;
          opacity: 0.5;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    .menu_nav {
      .item_link-icon,
      .item_group {
        width: min-content;
        margin: 16px 8px 6px;
        padding: 8px;

        &:hover {
          .item_group-icon {
            position: absolute;
            margin-top: 8px;
            width: 26px;
            height: 26px;
          }

          span,
          .item_group-title {
            display: block;
            margin-left: 18px;
          }
        }
      }

      .item_group-icon {
        display: block;
        width: 32px;
        height: 32px;
        transition: all 0.3s;
      }

      .item_link-icon span,
      .item_group-title {
        display: none;
      }
    }
  }

  @media screen and (max-width: 520px) {
    height: 60px;

    .menu_logo {
      left: 60px;
      width: 90px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: 45px;
      background-position: left center;
    }

    .menu_nav {
      width: 100%;
      padding: 0 12px 1rem 4px;
      flex-direction: column;

      .item_link-icon,
      .item_group {
        width: 100%;
        margin: 6px 4px;
        padding: 2px 0;
        border-radius: 6px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
        @include themify($themes) {
          background-color: themed("colorCorporate");
        }

        &:hover {
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
          @include themify($themes) {
            background-color: themed("colorCorporate");
          }

          .item_link {
            display: block;

            a {
              padding-left: 2rem;
            }
          }

          span,
          .item_group-title {
            margin-bottom: 0;
            margin-left: 22px;
          }
        }
      }

      .item_link-icon.item_group-icon,
      .item_group-icon {
        position: absolute;
        margin-top: 8px;
        left: 4px;
        width: 24px;
        height: 24px;
      }

      .item_link-icon span,
      .item_group-title {
        display: block;
        text-align: left;
        margin-left: 22px;
      }
    }

    .menu_profile {
      width: 60px;
      min-width: 60px;

      .menu_burger {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        margin: 10px;
        cursor: pointer;

        .menu_burger_icono {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.back_pressed {
  position: fixed;
  top: 75px;
  left: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  z-index: 99;
  @include themify($themes) {
    color: themed("textPrimary");
  }

  @media screen and (max-width: 520px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    padding: 8px;
  }
}

.wrapper {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
  padding: 90px 12px 16px;

  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("bkgBody");
  }

  .container,
  .container_error {
    max-width: 1280px;
    margin: 0 auto;
    transition: all 0.3s;
    border-radius: 16px;
    @include themify($themes) {
      background-color: themed("bkgBodyContra");
    }
  }

  .container {
    display: block;
    padding: 2rem 1rem;

    .container_messages {
      h2 {
        font-size: 1.6rem;
        margin: 1rem 1.2rem 2rem;
      }

      svg {
        width: 2.8rem;
        height: 2.8rem;
        margin-bottom: -0.6rem;
        margin-right: 0.8rem;
      }
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      padding: 1rem 14px;
    }
  }

  .container_error {
    display: flex;
    flex-flow: row nowrap;
    padding: 3rem 2rem;

    p {
      width: 50%;
      padding: 6rem 4rem 0 0;

      span {
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }
    }

    img {
      width: 50%;
    }

    @media screen and (min-width: 1024px) {
      padding: 3rem 6rem;
    }

    @media screen and (max-width: 620px) {
      padding: 0 1rem 2rem;
      flex-wrap: wrap-reverse;

      p {
        width: 100%;
        margin-top: -2rem;
        padding: 0 1rem 0;
        font-size: 1.2rem;

        span {
          margin-top: 2rem;
          margin-bottom: 2.2rem;
          font-size: 1.5rem;
        }

        a {
          font-size: 1.2rem;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

footer {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  @include themify($themes) {
    color: themed("textPrimary");
    background-color: themed("bkgBody");
  }

  .footer {
    padding: 0 20px 20px;

    p {
      font-size: 0.9rem;
      text-align: center;
    }

    i {
      font-size: 0.7rem;
      @include themify($themes) {
        color: rgba(themed("textPrimary"), 0.5);
      }
    }
  }
}
