$color-text-print: rgb(20, 20, 20);
$color-dorado: rgb(189, 160, 24);
$color-corporate: rgb(32, 32, 32);
$color-accent: rgb(240, 239, 232);

$color-blue: rgb(112, 187, 253);
$color-blue-hover: darken($color-blue, 10%);

$color-additional: rgb(150, 150, 150);
$color-additional-hover: darken($color-additional, 10%);

$themes: (
  light: (
    logoImg: url(../images/logo/logo_dark.png),
    logoCard: url(../images/logo/logo_light.png),
    bkgCard: rgba(255, 255, 255, 0.6),
    bkgShadow: rgba(230, 230, 255, 0.5),
    bkgWhite: rgba(255, 255, 255, 0.7),
    bkgColorAlert: rgb(248, 58, 58),
    bkgBody: rgb(240, 240, 240),
    bkgBodyContra: rgb(255, 255, 255),
    bkgInbox: rgb(240, 240, 240),
    bkgBtnsHover: rgba(190, 190, 255, 0.5),
    textList: rgb(30, 30, 30),
    textInverted: rgb(240, 240, 240),
    textPrimary: rgb(80, 80, 80),
    colorCorporate: rgb(250, 250, 255),
    colorHeaderDataPicker: rgb(109, 176, 255),
    colorHover: rgb(211, 219, 243),
    colorInterLine: rgb(230, 230, 255),
    colorHoverList: rgb(220, 220, 255),
    colorSelectList: rgb(180, 210, 255),
    colorIcono: rgb(21, 58, 97),
    colorIconoHover: rgb(61, 118, 137),
    colorBorder: rgba(51, 50, 70, 0.2),
    colorBorderTenue: rgb(242, 244, 247),
    colorBorderFieldset: rgba(68, 47, 47, 0.3),
    colorCategory: rgb(150, 170, 255),

    colorScrollbar: rgb(180, 191, 208),
    colorLinck: rgb(34, 128, 209),
    colorError: rgb(255, 19, 19),
    colorTitle: rgb(100, 103, 119),
    colorDelete: rgb(255, 90, 90),
    colorSeparator: rgba(35, 35, 41, 0.6),
  ),
  dark: (
    logoImg: url(../images/logo/logo_dark.png),
    logoCard: url(../images/logo/logo_dark.png),
    bkgCard: rgba(35, 35, 41, 0.7),
    bkgShadow: rgba(15, 15, 15, 0.5),
    bkgWhite: rgba(120, 120, 120, 0.5),
    bkgColorAlert: rgb(224, 0, 0),
    bkgBody: rgb(35, 35, 41),
    bkgBodyContra: rgb(42, 42, 49),
    bkgInbox: rgb(35, 35, 41),
    bkgBtnsHover: rgba(90, 90, 250, 0.1),
    textList: rgb(220, 220, 220),
    textInverted: rgb(80, 80, 80),
    textPrimary: rgb(220, 220, 220),
    colorCorporate: $color-corporate,
    colorHeaderDataPicker: rgb(6, 50, 99),
    colorHover: rgb(56, 55, 63),
    colorInterLine: rgb(35, 35, 40),
    colorHoverList: rgb(35, 35, 55),
    colorSelectList: rgba(40, 40, 140, 0.6),
    colorIcono: lighten(rgb(21, 58, 97), 30%),
    colorIconoHover: lighten(rgb(21, 58, 97), 40%),
    colorBorder: rgb(51, 50, 70),
    colorBorderTenue: rgb(51, 51, 58),
    colorBorderFieldset: rgba(160, 160, 160, 0.6),
    colorCategory: rgb(80, 69, 159),

    colorScrollbar: rgb(96, 96, 113),
    colorLinck: rgb(112, 187, 253),
    colorError: rgb(255, 90, 90),
    colorTitle: rgb(143, 203, 246),
    colorDelete: rgb(255, 130, 130),
    colorSeparator: rgba(255, 255, 255, 0.6),
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
