.btn {
  position: relative;
  padding: 8px 20px;
  margin: 8px 12px;
  font-size: 1rem;
  transition: all 0.1s;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid;
  white-space: nowrap;
  cursor: pointer;
  z-index: 0;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:before {
    position: absolute;
    height: 0;
    width: 225%;
    transition:
      width 0.3s ease-in-out,
      height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
    @include themify($themes) {
      background-color: themed("bkgBtnsHover");
    }
  }

  &:hover {
    outline: none;
    @include themify($themes) {
      background-color: themed("bkgBtnsHover");
    }

    &:before {
      height: 500%;
    }
  }

  // box-shadow:
  //   2px 3px 6px rgba(255, 255, 255, 0.3) inset,
  //   -1px -1px 6px rgba(0, 0, 0, 0.8) inset;

  &:active {
    transform: translate(2px, 2px);
    // box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.8) inset,
    //   -1px -1px 6px rgba(255, 255, 255, 0.3) inset;
  }

  &.btn_rounded {
    border-radius: 30px !important;
  }

  &.btn_icon {
    padding: 8px 30px !important;
    font-size: 0.9rem !important;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  &.btn_primary {
    font-size: 1.1rem;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgInbox");
      border: 1px solid themed("colorBorderFieldset");
    }
  }

  &.btn_with_icon {
    display: block;
    width: max-content;
    margin: 2rem 0 0;
    padding: 0.6rem 2.6rem 0.6rem 1rem;
    font-size: 1.1rem;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgInbox");
      border: 1px solid themed("colorBorderFieldset");
    }

    svg {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    &._left {
      padding: 0.6rem 1rem 0.6rem 2.6rem;

      svg {
        position: absolute;
        right: auto;
        left: 0.5rem;
      }
    }

    &._slim {
      height: min-content;
      margin-bottom: 1.8rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;

      svg {
        top: 0.3rem;
      }
    }

    @media screen and (max-width: 520px) {
      &._slim {
        margin: 0 8px 1.8rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;

        svg {
          top: 0.5rem;
        }
      }
    }
  }

  &.btn_add_to_list {
    display: block;
    width: max-content;
    position: relative;
    margin: 1rem auto 0;
    padding: 0.6rem 3rem 0.6rem 1rem;
    text-decoration: none;
    font-size: 1.1rem;
    border-radius: 8px;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgBody");
      border: 1px solid themed("colorBorderFieldset");
    }

    svg {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 2rem;
      height: 2rem;
    }

    &._left {
      margin: 1rem auto 0 0;
    }

    &._right {
      margin: 1rem 0 0 auto;
    }
  }

  &.link_to_btn {
    display: block;
    width: max-content;
    position: relative;
    margin: 1rem auto 0;
    padding: 0.4rem 1rem;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgBody");
      border: 1px solid themed("colorBorderFieldset");
    }

    &._left {
      margin: 1rem auto 0 0;
    }

    &._right {
      margin: 1rem 0 0 auto;
    }

    &._disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &.btn_lazy_load {
    display: block;
    width: 130px;
    position: relative;
    margin: 1rem auto 3rem;
    padding: 0.6rem 1rem 0.8rem;
    font-size: 1.1rem;
    text-align: center;
    border-radius: 8px;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("bkgBody");
      border: 1px solid themed("colorBorderFieldset");
    }

    svg {
      display: block;
      position: relative;
      width: 1.1rem;
      height: 1.1rem;
      margin: auto;
    }
  }

  &.unable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.btn_pequenio {
    display: block;
    width: max-content;
    position: absolute;
    top: -2rem;
    right: 0.9rem;
    margin: 0 auto 0;
    padding: 0.2rem 0.8rem;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px;
    @include themify($themes) {
      color: themed("textPrimary");
      background-color: themed("colorCorporate");
      border: 1px solid themed("colorBorderFieldset");
    }
  }

  @media screen and (max-width: 520px) {
    padding: 12px 26px;
    font-size: 1.2rem;
  }
}

button:focus,
button:active {
  outline: none;
}
